import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AboutState } from '../../store/about.reducer';
import * as AboutActions from '../../store/about.actions';
import * as AboutSelectors from '../../store/about.selectors';
import { VersionType } from '../../store/about.models';
import { ElectronService } from '@chassis/shared/services/electron';
import { ConnectFrontendService } from '@chassis/desktop/connect/frontend';

@Component({
  selector: 'about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.scss'],
  standalone: false,
})
export class AboutSectionComponent {
  public ip = '';
  public customers = [];
  readonly store = inject(Store<AboutState>);
  readonly versionType = VersionType;

  readonly isElectron = inject(ElectronService).isElectron;
  readonly connectService = inject(ConnectFrontendService);

  webCurrent$ = this.store.select(AboutSelectors.selectWebVersionState);
  desktopCurrent$ = this.store.select(AboutSelectors.selectDesktopVersionState);
  checking$ = this.store.select(AboutSelectors.selectChecking);

  copyright = `Copyright ©${new Date().getFullYear()} APFusion, Inc.`;

  onCheckForUpdates() {
    this.store.dispatch(AboutActions.checkForUpdates());
  }
}
